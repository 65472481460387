import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import utils from 'utils';
import { RESERVATIONS, ERRORS } from 'constants';
import SecureConnectionDisplay from 'components/SecureConnectionDisplay';
import IbmPaymentIframe from 'components/IbmPaymentIframe';
import GenericError from 'components/GenericError';
import Button from 'components/Button';

const { IBM_SUCCESS, IBM_FAILURE } = RESERVATIONS;
const { PRIORITY_ERROR } = ERRORS;

/**
 * GuaranteedResIframeModalContent renders IbmPaymentIframe component that accepts Credit Card info and saves payment_id
 * to commit state on submission.
 *
 * @param {object} props - React Props
 * @param {function} props.getInflightPaymentDetails - gets card details after Ibm confirms card
 * @param {function} props.clearCardSubmissionUrl - used to clear cardSubmissionUrl so that IbmPaymentIframe resets
 * @param {function} props.handleClose - passed from Modal wrapper
 * @param {function} props.scrollToModalTop - passed from Modal wrapper
 * @return {JSX}
 */

export default function GuaranteedResIframeModalContent({
  getInflightPaymentDetails,
  clearCardSubmissionUrl,
  handleClose,
  scrollToModalTop,
}) {
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const onSubmit = (data) => {
    if (data === IBM_SUCCESS) {
      getInflightPaymentDetails();
      handleClose();
    }
    if (data === IBM_FAILURE) {
      setShowError(true);
      scrollToModalTop();
      clearCardSubmissionUrl();
    }
  };

  const renderError = () => {
    const closeModalAndNavigate = () => {
      handleClose();
      // using setTimeout to allow full handleClose execution stack to complete before navigating, otherwise the
      // scroll lock stays on. TODO: see if possible to refactor Modal to avoid this
      setTimeout(() => history.push(RESERVATIONS.RESFLOW_PATHS_CONFIG.vehicle[0]));
    };

    return (
      <GenericError
        errors={[{ priority: PRIORITY_ERROR, message: utils.i18n('guaranteed_res_modal_error_message') }]}
        description={utils.i18n(
          'guaranteed_res_modal_error_description',
          [
            <Button key={0} onClick={closeModalAndNavigate} link>
              {utils.i18n('guaranteed_res_modal_error_switch_vehicle')}
            </Button>,
          ],
          { jsx: true }
        )}
      />
    );
  };

  return (
    <div className='guaranteed-res-iframe-modal'>
      {showError && renderError()}

      <p className='modal-themed__copy'>{utils.i18n('guaranteed_res_modal_description')}</p>

      <p className='guaranteed-res-iframe-modal__legend'>{utils.i18n('guaranteed_res_modal_required_indication')}</p>

      <SecureConnectionDisplay />

      <h4 className='guaranteed-res-iframe-modal__iframe-header'>{utils.i18n('guaranteed_res_modal_iframe_header')}</h4>

      <IbmPaymentIframe onSubmit={onSubmit} />
    </div>
  );
}

GuaranteedResIframeModalContent.propTypes = {
  getInflightPaymentDetails: PropTypes.func.isRequired,
  clearCardSubmissionUrl: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  scrollToModalTop: PropTypes.func.isRequired,
};
